.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #f2f2f2;
  min-height: 100vh;
}

.Main {
  display: flex;
  flex: auto;
  height:auto;
  overflow-x: hidden;
  background-color: #EBEBEB;
}

.Header {
  position: absolute;
  top: 0;
  overflow: hidden;
}

.Footer {
  position: absolute;
  bottom: 0;
  overflow: hidden;
  overflow-x: hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.errorShow{
  color: red !important;
}
.errorHidden{
  display: none;
}