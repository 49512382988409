.errorDiv {
    height: 100%;
}

.notFoundImg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -150px;
    margin-left: -400px;
}
.message-box {
    height: 200px;
    width: 380px;
    position: absolute;
    top: 40%;
    left: 50%;
    margin-top: -50px;
    margin-left: 50px;
    font-weight: 300;
}
.message-box h1 {
    font-size: 60px;
    line-height: 46px;
    margin-bottom: 40px;
}

@keyframes float {
    100% {
        transform: translateY(20px);
    }
}
/* @media (max-width: 450px) {
    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -250px;
        margin-left: -190px;
    }
    .message-box {
        top: 50%;
        left: 50%;
        margin-top: -100px;
        margin-left: -190px;
        text-align: center;
    }
} */

.errorMessage {
    line-height: 60px !important;
}

.polibuda {
    animation: float 1s infinite ease-in-out alternate;
    animation-delay: .2s;
}
