.messages-content-wrapper{
    width:100%
}
.messages-content-wrapper .button-wrapper{
    width:100%;
    margin-left: -16px;
    padding: 2.5vh;
}
.messages-content-wrapper .button-wrapper a{
    background-color: #30376c;
}
.messages-content-wrapper .list-elem {
    background-color: #fff;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    margin-left: -2rem;
    padding-left: 4rem !important;
    cursor: pointer;
}
.messages-content-wrapper .list-elem:hover{
    background-color: #30376c;
    color: #fff;  
}
.messages-content-wrapper .active{
    background-color: #30376c !important;
    color: #fff;
}
.messages-content-wrapper .list{
    width: 100%;
    position: relative;
    overflow: auto;
    max-height: 500px;
    padding-bottom: 0;
}

.messages-content-wrapper .list ul { padding: 0; }
.messages-content-wrapper .list::-webkit-scrollbar {
    width: 3px;
}
.messages-content-wrapper .list::-webkit-scrollbar-track {
    background-color: #fff;
}
.messages-content-wrapper .list::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 1rem;
}
.messages-content-wrapper .li-text p{
    color: unset;
    font-size: 11px;
}
.messages-content-wrapper .list-elem .unread .MuiTypography-root,
.messages-content-wrapper .unread{
    font-weight: bold !important;
}
@media (min-width: 1200px){
    .messages-content-wrapper .menuTitle > div{
        min-width: 150px !important;
        min-height: 150px !important;
    }
}