.recruitment-results-wrapper{
    width:100%
}
.recruitment-results-wrapper .button-wrapper{
    width:100%;
    margin-left: -16px;
    padding: 2.5vh;
}
.recruitment-results-wrapper .button-wrapper a{
    background-color: #30376c;
}

.input-margin-bottom{
    margin-bottom: 1rem !important;
}

.recruitment-results-wrapper .makeStyles-daneOsoboweGridField-28 {
    padding: 0;
}

.download-link{
    text-decoration: none;
    color: rgba(0, 0, 0, 0.87);
}
.recruitment-results-wrapper .blue-header {
    background-color: #30376c;
}

.recruitment-results-wrapper .blue-header th{
    color: #fff;
}

.recruitment-button{
    background-color: #30376c !important;
    color: #fff !important;
    min-width: 150px !important;
}

.react-pdf__Page__textContent{
    border:1px solid rgba(0, 0, 0, 0.3);
}

@media (min-width: 1200px){
    .recruitment-results-wrapper .menuTitle > div{
        min-width: 150px !important;
        min-height: 150px !important;
    }
    .half-width-input{
        width:48% !important;
    }
}

.validationStringInput{
    width: calc(100% - 2rem);
    margin-top: 1rem !important;
}

.validationStringInput label{
    color: rgba(0, 0, 0, 0.6) !important;
}

.validationStringInput fieldset{
    border: 1px solid rgb(133, 133, 133) !important;
}
.validationStringInput input{
    width: 100%;
}
.validationStringInputError fieldset{
    border: 1px solid rgb(255, 0, 0) !important;
}

.resignationTextImage{
    position:relative;
}

.resignationTextImage .resignationLogo{
    position: absolute;
    top:10px;
    left:10px;
    width:200px;
    filter: drop-shadow(1px 1px 0px #000);
}

.resignationTextImage .resignationText{
    position: absolute;
    bottom:10px;
    width:100%;
    padding: 0 10px;
    text-align: center;
    color: #fff;
    font-weight: bold;
    text-shadow: 2px 1px 0px rgba(66, 68, 90, 1);
}

.css-jm0nt1-MuiFormControl-root .css-gy3icv-MuiFormLabel-root.Mui-focused {
    color: #000;
}
.css-jm0nt1-MuiFormControl-root .css-gy3icv-MuiFormLabel-root.Mui-error {
    color: rgb(255, 0, 0) !important;
}